import '../App.css';
import HomePage from '../components/HomePage';

function Home() {

  return (
    <div>
      <HomePage />
    </div>
  );
}

export default Home;