import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import frame from '../assets/svgs/blackBg.svg';
import upperLine from '../assets/svgs/upperLine.svg';
import bottomLine from '../assets/svgs/bottomLine.svg';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import Card from './ImageCard.js';
import InfiniteScroll from "react-infinite-scroller";
import getNfts from "./utils/GetNfts";

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'center',
  fontSize: '18px',
  fontFamily: 'Jura',
  padding: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  alignItems: "center",
  justify: "center"
}));

const truncateText = (text, length) => {
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length - 1) + "...";
}

export default function AllGallery({userAddress}) {
  const [allNfts, setAllNfts] = useState([]);
  const [display, setDisplay] = useState([]);
  const [hasMore, setHasMore] = useState(false); 
  const [pageKeys, setPageKeys] = useState();
  const [hasRemainingNfts, setHasRemainingNfts] = useState(false);

  useEffect(() => {
    getNfts(userAddress, null).then(([validNfts, newPageKeys, hasMoreNfts]) => {
      setAllNfts(validNfts);
      setDisplay(validNfts && validNfts.length > 0 ? validNfts.slice(0, 4) : []);
      setHasMore(validNfts && validNfts.length > 4 ? true : false);
      setPageKeys(newPageKeys);
      setHasRemainingNfts(hasMoreNfts);
    });
  }, [userAddress]);


  const addMoreNfts = () => {
    const curr = display.length;
    if (curr < allNfts.length) {
      setDisplay(display.concat(allNfts.slice(curr, curr + 4)));
    }
    else {
      if (!hasRemainingNfts) {
        setHasMore(false);
        return;
      }
      getNfts(userAddress, pageKeys).then(([nextNfts, nextPageKeys, hasMoreNfts]) => {
        setAllNfts(allNfts.concat(nextNfts));
        setPageKeys(nextPageKeys);
        setDisplay(display.concat(nextNfts.slice(0, 4)));
        setHasRemainingNfts(hasMoreNfts);
        setHasMore((curr + 4 < allNfts.length + nextNfts.length) || hasMoreNfts ? true : false);
      });
    }
  }
  
  const arrangeAllNfts = () => {
    const validNfts = display;
    let odd = true, res = [];
    if (!validNfts) {
      return res;
    }
    for (let i = 0; i < validNfts.length; i++) {
      const nft = validNfts[i];
      const addr = nft.media[0].gateway;
      const title = nft.title ? nft.title : "Unknown Title";
      const collection = nft.contract.name ? nft.contract.name : "Unknown Collection";
      if (odd && i !== 0) {
        res.push(
          <Grid container justifyContent="center" md={12}>
            <Item>
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 40H80M40 0L40 80M46 40C46 43.3137 43.3137 46 40 46C36.6863 46 34 43.3137 34 40C34 36.6863 36.6863 34 40 34C43.3137 34 46 36.6863 46 40Z" stroke="#FA9E2C"/>
              </svg>
            </Item>
          </Grid>
        )
      }
      if (odd && i === validNfts.length - 1) {
        res.push(
          <Grid container md={6} justifyContent="center">
            <div className="mt-8">
              <button onClick={() => window.open(nft.opensea, "_blank")}>
                <Item className="card">
                  <Card image={addr} />
                  <div className="relative -top-[110px] left-[36px] z-10">
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '16px', fontFamily: 'Orbitron'}}>{truncateText(title, 30)}</Typography>
                  </div>
                  <div className="relative -top-[95px] left-[-42px] z-10">
                    <Typography align="right" component="legend" color="white" sx={{fontSize: '11px', fontFamily: 'Orbitron'}}>{truncateText(collection, 23)}</Typography>
                  </div>
                </Item>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[55px] left-[-50px]">
                  <path d="M0.999999 0V24H25" stroke="#7D7C7C"/>
                </svg>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[585px] left-[440px]">
                  <path d="M24 25V1H0" stroke="#7D7C7C"/>
                </svg>
              </button>
            </div>
          </Grid>
        )
      }
      else if (odd) {
        res.push(
          <Grid container md={6} justifyContent="center">
            <button onClick={() => window.open(nft.opensea, "_blank")}>
              <div className="mt-8">
                <Item className="card">
                  <Card image={addr} />
                  <div className="relative -top-[110px] left-[36px] z-10">
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '16px', fontFamily: 'Orbitron'}}>{truncateText(title, 30)}</Typography>
                  </div>
                  <div className="relative -top-[95px] left-[-42px] z-10">
                    <Typography align="right" component="legend" color="white" sx={{fontSize: '11px', fontFamily: 'Orbitron'}}>{truncateText(collection, 23)}</Typography>
                  </div>
                </Item>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[55px] left-[-50px]">
                  <path d="M0.999999 0V24H25" stroke="#7D7C7C"/>
                </svg>
              </div>
            </button>
          </Grid>
        )
      }
      else {
        res.push(
          <Grid container md={6} justifyContent="center">
            <button onClick={() => window.open(nft.opensea, "_blank")}>
              <div className="mt-8">
                <Item className="card">
                  <Card image={addr} />
                  <div className="relative -top-[110px] left-[36px] z-10">
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '16px', fontFamily: 'Orbitron'}} >{truncateText(title, 30)}</Typography>
                  </div>
                  <div className="relative -top-[95px] left-[-42px] z-10">
                    <Typography align="right" component="legend" color="white" sx={{fontSize: '11px', fontFamily: 'Orbitron'}} >{truncateText(collection, 23)}</Typography>
                  </div>
                </Item>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[560px] left-[440px]">
                  <path d="M24 25V1H0" stroke="#7D7C7C"/>
                </svg>
              </div>
            </button>
          </Grid>
        )
      }
      odd = !odd;
    }
    return res;
  }

  return (
  <div>
    <Container maxWidth="lg" sx={{marginTop: '40px'}}>
      <img src={upperLine} className="mx-auto"/>
    </Container>
    <Container maxWidth="xlg" sx={{marginTop: '40px'}}>
      <Box sx={{ backgroundImage: `url(${frame})`, backgroundRepeat: "repeat-y", backgroundPosition: "center", 
      marginTop: "30px", marginBottom: "30px"}} >
      <div style={{height: "1400px"}} className="overflow-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={() => addMoreNfts()}
          hasMore={hasMore}
          useWindow={false}
          loader={
            <div key="loading" className="loader">
              Loading ...
            </div>
          }
        >
          <Grid container spacing={0} justifyContent="center" maxWidth="lg" sx={{marginLeft: 'auto', marginRight: 'auto'}}>
            {arrangeAllNfts()}
          </Grid>
        </InfiniteScroll>
      </div>
      </Box>
    </Container>
    <Container maxWidth="lg">
      <img src={bottomLine} className="mx-auto"/>
    </Container>
  </div>
  );
}