import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import AdjustableButton from './AdjustableButton'
import fontSizes from './utils/FontSizeUtils';
import useMobileSize from './utils/MobileSizeUtils';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'left',
  fontSize: '18px',
  fontFamily: 'Jura',
  padding: theme.spacing(2)
}));

export default function News() {
  const isMobile = useMobileSize();
  return (
    <div>
      {NewsList()}
    </div>
  )
}

const NewsList = () => {
  return (
    <div>
    <Container maxWidth="md">
      <Stack direction="column">
        <Typography align="center" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 004</Typography>
        <Typography align="center" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>
          <div className="glitch" data-glitch="NEWS">NEWS</div>
        </Typography>
      </Stack>
    </Container>
    <Container maxWidth="lg" sx={{marginTop: '50px'}}>
      <Grid container md={12} >
        <Grid md={12} xs={12}>
          <Item>
            <Divider sx={{bgcolor: '#4C4C4C'}} />
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <Grid container md={12}>
              <Grid md={6} xs={12}>
                <img src={"https://mew3publicresources.s3.amazonaws.com/featuredimg_bg.jpeg"} width={220} className="rounded-lg mx-auto"/>
              </Grid>
              <Grid md={6} xs={12}>
                <Typography align="center" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Orbitron', marginLeft: '20px'}}>Yahoo!Finance</Typography>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <Stack direction="column" spacing={2}>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura'}}>Unlocking the Future of Your Digital Realm: MEW3 Genesis NFT Collection and Innovative 3D Portfolio Platform...</Typography>
              <a target="_blank" href="https://finance.yahoo.com/news/unlocking-future-digital-realm-mew3-000000277.html">
                <AdjustableButton text="Read it" buttonClassName="btn" />
              </a>
            </Stack>
          </Item>
        </Grid>
        <Grid md={12} xs={12}>
          <Item>
            <Divider sx={{bgcolor: '#4C4C4C'}} />
          </Item>
        </Grid>
      </Grid>
      <Grid container md={12} >
        <Grid md={6}>
          <Item>
            <Grid container md={12}>
              <Grid md={6} xs={12}>
                <img src={"https://www.rd.com/wp-content/uploads/2022/08/GettyImages-1363804594-e1660830829817.jpg"} width={220} className="rounded-lg mx-auto"/>
              </Grid>
              <Grid md={6} xs={12}>
                <Typography align="center" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Orbitron', marginLeft: '20px'}}>Join us on discord</Typography>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <Stack direction="column" spacing={2}>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura'}}>Engage in our NFT Communities: Join NFT-focused communities on platforms like Discord. Share your gallery with fellow collectors and enthusiasts, and participate in discussions about the NFT space.</Typography>
              <AdjustableButton text="Read it" buttonClassName="btn" />
            </Stack>
          </Item>
        </Grid>
        <Grid md={12} xs={12}>
          <Item>
            <Divider sx={{bgcolor: '#4C4C4C'}} />
          </Item>
        </Grid>
      </Grid>
      <Grid container md={12} >
        <Grid md={6}>
          <Item>
            <Grid container md={12}>
              <Grid md={6} xs={12}>
                <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmRAC1K2rrXvZdsKuEypA8FYrm-CCFEf_57Q&usqp=CAU"} width={220} className="rounded-lg mx-auto"/>
              </Grid>
              <Grid md={6} xs={12}>
                <Typography align="center" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Orbitron', marginLeft: '20px'}}>We hit another milestone!</Typography>
              </Grid>
            </Grid>
          </Item>
        </Grid>
        <Grid md={6}>
          <Item>
            <Stack direction="column" spacing={2}>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura'}}>Our 3D NFT gallery is ready to launch. Don't miss out on this unique chance to be a part of the future of digital art and secure your place in the forefront of this groundbreaking technology.</Typography>
              <AdjustableButton text="Read it" buttonClassName="btn" />
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </Container>
    </div>
  );
};

