import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from '../assets/hlogo-white.png';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
import { GlobalContext } from '../context/UserContext';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import awsmobile from '../aws-exports';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { getUser as  getUserQuery, searchUsers as searchUserQuery} from '../graphql/queries';
Amplify.configure(awsmobile);

function ResponsiveAppBar() {
  const [user, setUser] = React.useContext(GlobalContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [search, setSearch] = React.useState(" ");
  const [userlink, setUserlink] = React.useState("/");
  const [alias, setAlias] = React.useState("Searching...");
  const [username, setUsername] = React.useState("");
  const [foundImg, setFoundImg] = React.useState("/placeholder1.png")

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    handleSearch();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const account = useAccount({
    onConnect({ address }) {
      // address = "0xd0594d3984eb00D2730Db00531F87E89A63645Dd";
      // address = "0x3D7e39DFa6d78472A5d660a343d2060509d1Ef7d";
      // getNfts(address, user).then(([validNfts, pageKeys, hasMoreNfts]) => setUser({address: address, allNfts: validNfts, pageKeys: pageKeys, hasMoreNfts: hasMoreNfts}));
      setUser({address: address});
      API.graphql(graphqlOperation(searchUserQuery, {filter: { address: { eq: address }}, authMode: 'API_KEY'})).then((response) => 
        {if (response.data.searchUsers.items.length > 0) setUsername("/gallery/" + response.data.searchUsers.items[0].username)
          else setUsername('/register')});
    },
    onReconnect({ address }) {
      // address = "0xd0594d3984eb00D2730Db00531F87E89A63645Dd";
      // address = "0x3D7e39DFa6d78472A5d660a343d2060509d1Ef7d";
      // getNfts(address, user).then(([validNfts, pageKeys, hasMoreNfts]) => setUser({address: address, allNfts: validNfts, pageKeys: pageKeys, hasMoreNfts: hasMoreNfts}));
      setUser({address: address});
      API.graphql(graphqlOperation(searchUserQuery, {filter: { address: { eq: address }}, authMode: 'API_KEY'})).then((response) => 
        {if (response.data.searchUsers.items.length > 0) setUsername("/gallery/" + response.data.searchUsers.items[0].username)
          else setUsername('/register')});
    }
  })

  async function handleSearch() {
    const getUserResult = await API.graphql(graphqlOperation(getUserQuery, {username: search, authMode: 'API_KEY'}));
    if (getUserResult.data.getUser) {
      setAlias(search);
      setUserlink("/gallery/"+search);
      setFoundImg(getUserResult.data.getUser.profilePic);
    } else {
      setAlias("User Not Found");
    }
  }

  return (
    <AppBar color="transparent" position="static">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <a href="/">
            <img className="h-7" src={logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
          </a>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="page" onClick={handleCloseNavMenu} component={Link} to={username}>
                <Typography textAlign="center" sx={{fontFamily: 'Tomorrow'}}>my gallery</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300, marginLeft: 'auto', marginTop: '10px', marginBottom: '10px' }}
            >
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleOpenUserMenu}>
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search User by Username..."
                inputProps={{ 'aria-label': 'search user...' }}
                onChange={(newValue) => setSearch(newValue.target.value)}
              />
            </Paper>
            <Menu
              id="user-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{
                display: { md: 'block', xs: 'none' }
              }}
            >
              <MenuItem key="page" onClick={handleCloseUserMenu} component={Link} to={userlink} sx={{width: 300}}>
                <Avatar alt="Found User" src={foundImg} />
                <Typography textAlign="center" sx={{fontFamily: 'Tomorrow', ml: '20px'}}>{alias}</Typography>
              </MenuItem>
            </Menu>

            <Button
              key="search"
              onClick={handleCloseNavMenu} href={username}
              sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'Tomorrow', ml: 'auto' }}
            >
              my gallery
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }} className="ml-4 text-base font-['Tomorrow']">
            <ConnectButton chainStatus="icon" showBalance={false}/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;