import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import frame from '../assets/svgs/profileFrame.svg';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import label from '../assets/svgs/nftLabel.svg';
import React from 'react';
import Chip from '@mui/material/Chip';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Stack from '@mui/material/Stack';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'left',
  fontSize: '18px',
  fontFamily: 'Jura',
  padding: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export default function Profile({userAlias, userAddress, userDescription, userProfilePic, isAuthenticated = false}) {

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid container spacing={0} sx={{mt: '7rem', backgroundImage: `url(${frame})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
          <Grid md={3} xs={12}>
            <div className="mt-10 -mb-8 mx-auto">
              <Item>
                <svg width="180" height="180" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="cut-off-outside">
                    <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H152.686C154.675 0.5 156.583 1.29018 157.99 2.6967L177.303 22.0104C178.71 23.4169 179.5 25.3246 179.5 27.3137V172C179.5 176.142 176.142 179.5 172 179.5H20C15.8579 179.5 12.5 176.142 12.5 172V92.6977C12.5 90.3452 11.525 88.0979 9.80716 86.4907L2.87603 80.0061C1.36026 78.5879 0.5 76.605 0.5 74.5293V8Z" fill="none" stroke="url(#paint0_linear_839_25)"/>
                  </clipPath>
                </defs>
                <image xlinkHref={userProfilePic} height="180" width="180" clipPath="url(#cut-off-outside)"/>
                <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H152.686C154.675 0.5 156.583 1.29018 157.99 2.6967L177.303 22.0104C178.71 23.4169 179.5 25.3246 179.5 27.3137V172C179.5 176.142 176.142 179.5 172 179.5H20C15.8579 179.5 12.5 176.142 12.5 172V92.6977C12.5 90.3452 11.525 88.0979 9.80716 86.4907L2.87603 80.0061C1.36026 78.5879 0.5 76.605 0.5 74.5293V8Z" fill="none" stroke="url(#paint0_linear_839_25)"/>
                <defs>
                <linearGradient id="paint0_linear_839_25" x1="90" y1="0" x2="90" y2="180" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0"/>
                </linearGradient>
                </defs>
                </svg>
                <img src={label} alt="nftLabel" className="relative -top-[198px] left-[21px] z-10"/>
                <div className="relative -top-[226px] left-[28px] z-10">
                  <Typography align="left" component="legend" color="white" sx={{fontSize: '16px', fontFamily: 'Orbitron'}}>1234</Typography>
                </div>
              </Item>
            </div>
          </Grid>
          <Grid container md={9} xs={12}>
            <Grid container xs={12}>
              <Grid md={6} xs={12}>
                <Item>
                  <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 001</Typography>
                  <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '24px', fontFamily: 'Orbitron'}}>{userAlias}</Typography>
                  <Typography align="left" component="legend" color="gray">{userAddress}</Typography>
                  <Stack direction="row" className="mt-2">
                    <Chip icon={<EmojiEventsIcon />} label="Pioneer Adopters" color="success" size="small" className="mr-2"/>
                    <Chip icon={<VerifiedIcon />} label="MEW3 NFT Holder" color="primary" size="small"/>
                  </Stack>
                </Item>
              </Grid>
              <Grid md={6} xs={12}>
                <Item>
                  {isAuthenticated ? (
                    <Link to={`/profile/${userAlias}`}>
                      <button className="btn float-right mt-5">
                        <span className="btn__content">Edit</span>
                        <span className="btn__label"></span>
                      </button>
                    </Link>
                  ) : (
                    // Render something else when user is not authenticated, or nothing if desired
                    null
                  )}
                </Item>
              </Grid>
            </Grid>
            <Grid xs={12} className="mb-auto mt-[-1rem]">
              <Item>
                <Divider sx={{bgcolor: 'gray', mb: 2}} />
                <Typography align="left" component="legend" sx={{fontFamily: 'Orbitron'}}>{userDescription}</Typography>
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

