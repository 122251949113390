import { Alchemy, Network } from "alchemy-sdk";
const alchemyApiKey = "lXfGwC8un1Ggv6ew_R0KR-3cF9LwZy9B";
const contractAddress = "0xFfc3FbdB2682D2CAb3626Af259E2BF73577dCedd"
// const contractAddress = "0x7DaEC605E9e2a1717326eeDFd660601e2753A057"

async function verifyOwner(address) {
    if (!address) {
        return false;
    }
    const config = {
        apiKey: alchemyApiKey,
        network: Network.ETH_MAINNET,
    };

    const alchemy = new Alchemy(config);
    const res = await alchemy.nft.getOwnersForContract(contractAddress);
    if (res.owners && res.owners.includes(address.toLowerCase())) {
        return true;
    }
    return false;
}

export default verifyOwner;