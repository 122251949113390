import '../App.css';
import UserInfo from '../components/Register';
import React, { useState } from 'react';
import Container from '@mui/material/Container';

function Register() {
  const [tab, setTab] = useState(0);
  
  function handleSelectedClick() {
    setTab(0);
  }

  function handleAllClick() {
    setTab(1);
  }

  return (
    <div>
      <UserInfo/>
    </div>
  );
}

export default Register;