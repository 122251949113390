import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import heroImg from '../assets/svgs/hero.svg';
import nft1235Img from '../assets/svgs/nft1235.svg';
import Container from '@mui/material/Container';
import seperator from '../assets/svgs/seperator.svg';
import discord from '../assets/svgs/discord.svg';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Steps from './Steps';
import News from './News';
import HomePageDescription from './homepage/HomePageDescription';
import fontSizes from './utils/FontSizeUtils';
import ResizableImage from './homepage/HomePageDisplayImage';
import Preview from './Preview';
import GlitchingTypingText from "./GlitchingTypingText";
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { faClock, faIcons, fa3, faM, faE, faW } from '@fortawesome/free-solid-svg-icons';
import {TiChevronLeftOutline, TiChevronRightOutline} from 'react-icons/ti';
import Link from '@mui/material/Link';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'left',
  fontSize: '18px',
  fontFamily: 'Jura',
  padding: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export default function HomePage() {

  const [nftUrl, setNftUrl] = React.useState("7.png");

  function handleNFTChange() {
    if (nftUrl === "7.png") {
      setNftUrl("8.png");
    } else {
      setNftUrl("7.png");
    }
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Stack style={{ fontSize: '64px', fontFamily: 'Tomorrow', marginTop: '4rem', marginBottom: '3rem'}} spacing={2} direction="column" >
          <Container maxWidth="lg" sx={{marginBottom: '50px'}}>
            <Stack direction="column">
              <HomePageDescription />
            </Stack>
          </Container>
        </Stack>
        <ReactPlayer width="100%" height="100%" playing={true} muted={true} url={"https://mew3publicresources.s3.amazonaws.com/coverVideo.mp4"} loop={true} controls={false} config={{ file: { attributes: { controlsList: 'nodownload' } } }}/>
        <a href="https://opensea.io/collection/mew3-genesis/drop" target="_blank" rel="noreferrer">
        <button className="cyberbutton mt-12">
        <Stack direction="row">
          Mint now&nbsp; <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.svg" className="w-8"/> 
        </Stack>
        </button>
        </a>
      </Container>
      <div className="mt-28 mb-24 mx-auto spacing-2">
        <FontAwesomeIcon icon={faM} className="mr-1"/>
        <FontAwesomeIcon icon={faE} className="mr-1"/>
        <FontAwesomeIcon icon={faW} beat style={{color: "#fd9117",}} className="mr-1"/>
        <FontAwesomeIcon icon={fa3} bounce/>
      </div>
      <Container maxWidth="lg">
        <Stack direction="column">
          <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 002</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>
            <div className="glitch-wrapper">
               <div className="glitch" data-glitch="WHAT'S MEW3?">WHAT'S MEW3?</div>
            </div>
          </Typography>
        </Stack>
        <Grid container md={12} sx={{marginTop: '50px'}}>
          <Grid container md={6}>
            <Grid xs={12}>
              <Item>
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 40H80M40 0L40 80M46 40C46 43.3137 43.3137 46 40 46C36.6863 46 34 43.3137 34 40C34 36.6863 36.6863 34 40 34C43.3137 34 46 36.6863 46 40Z" stroke="#FA9E2C"/>
                </svg>
              </Item>
              <Item>
                <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Orbitron'}}>M &bull; E &bull; W &bull; 3</Typography>
              </Item>
            </Grid>
            <Grid xs={12}>
              <Item>
                <Typography align="left" component="legend" color="white" sx={{fontSize: '20px', fontFamily: 'Jura'}}>
                MEW3 is an innovative 3D personal homepage platform designed to showcase your prized NFT collection. With a simple click, you can instantly create a personalized 3D NFT gallery that suits your preferences. By minting our exclusive NFT, you'll enjoy unmatched access to our cutting-edge 3D personal homepage generation and become an early member. This NFT also grants you access to the limited dark cyberpunk gallery theme, exclusive to this release, making it a rare and valuable addition to your NFT collection.
                </Typography>
              </Item>
            </Grid>
          </Grid>
          <Grid md={6} xs={12}>
            <Stack direction="row">
              <button onClick={handleNFTChange}>
                <TiChevronLeftOutline className="mt-auto mb-auto text-4xl"/>
              </button>
              <Item>
                <svg viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="cut-off-outside">
                    <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H152.686C154.675 0.5 156.583 1.29018 157.99 2.6967L177.303 22.0104C178.71 23.4169 179.5 25.3246 179.5 27.3137V172C179.5 176.142 176.142 179.5 172 179.5H20C15.8579 179.5 12.5 176.142 12.5 172V92.6977C12.5 90.3452 11.525 88.0979 9.80716 86.4907L2.87603 80.0061C1.36026 78.5879 0.5 76.605 0.5 74.5293V8Z" fill="none" stroke="url(#paint0_linear_839_25)"/>
                  </clipPath>
                </defs>
                <image xlinkHref={nftUrl} height="180" width="180" clipPath="url(#cut-off-outside)"/>
                <path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H152.686C154.675 0.5 156.583 1.29018 157.99 2.6967L177.303 22.0104C178.71 23.4169 179.5 25.3246 179.5 27.3137V172C179.5 176.142 176.142 179.5 172 179.5H20C15.8579 179.5 12.5 176.142 12.5 172V92.6977C12.5 90.3452 11.525 88.0979 9.80716 86.4907L2.87603 80.0061C1.36026 78.5879 0.5 76.605 0.5 74.5293V8Z" fill="none" stroke="url(#paint0_linear_839_25)"/>
                <defs>
                <linearGradient id="paint0_linear_839_25" x1="90" y1="0" x2="90" y2="180" gradientUnits="userSpaceOnUse">
                <stop stopColor="white"/>
                <stop offset="1" stopColor="white" stopOpacity="0"/>
                </linearGradient>
                </defs>
                </svg>
                <Typography align="center" component="legend" color="white" sx={{marginRight: 'auto', marginTop: '1rem', fontSize: '20px', fontFamily: 'Orbitron'}}>
                  20K Unique <a className="colorlogo">MEW3 Genesis</a> NFTs
                </Typography>
                <Stack direction="row">
                  <Typography align="center" component="legend" color="white" sx={{marginRight: 'auto', marginTop: '1rem', fontSize: '20px', fontFamily: 'Orbitron'}}>
                    <FontAwesomeIcon icon={faEthereum} fade style={{color: "#ffffff",}} /> 0.02 ETH&nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Typography align="center" component="legend" color="gray" sx={{marginTop: '1rem', fontSize: '20px', fontFamily: 'Roboto'}}>
                    <FontAwesomeIcon icon={faClock} style={{color: "gray",}} /> Release date: 09/19/2023
                  </Typography>
                </Stack>
              </Item>
              <button onClick={handleNFTChange}>
                <TiChevronRightOutline className="mt-auto mb-auto text-4xl"/>
              </button>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <div className="mt-28 mb-28 mx-auto spacing-2">
        <FontAwesomeIcon icon={faM} className="mr-1"/>
        <FontAwesomeIcon icon={faE} className="mr-1"/>
        <FontAwesomeIcon icon={faW} beat style={{color: "#fd9117",}} className="mr-1"/>
        <FontAwesomeIcon icon={fa3} bounce/>
      </div>
      <Steps />
      <div className="mt-28 mb-28 mx-auto spacing-2">
        <FontAwesomeIcon icon={faM} className="mr-1"/>
        <FontAwesomeIcon icon={faE} className="mr-1"/>
        <FontAwesomeIcon icon={faW} beat style={{color: "#fd9117",}} className="mr-1"/>
        <FontAwesomeIcon icon={fa3} bounce/>
      </div>
      <News />
      <div className="mt-28 mb-28 mx-auto spacing-2">
        <FontAwesomeIcon icon={faM} className="mr-1"/>
        <FontAwesomeIcon icon={faE} className="mr-1"/>
        <FontAwesomeIcon icon={faW} beat style={{color: "#fd9117",}} className="mr-1"/>
        <FontAwesomeIcon icon={fa3} bounce/>
      </div>      <Container maxWidth="sm">
        <Stack direction="column">
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[-10px] ml-auto">
            <path d="M24 25V1H0" stroke="#7D7C7C"/>
          </svg>
          <img src={discord} className="mx-auto mb-5"/>
          <Typography align="center" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 005</Typography>
          <Typography align="center" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>
            <div className="glitch" data-glitch="JOIN US ON DISCORD">JOIN US ON DISCORD</div>
          </Typography>
          <Link target="_blank" href="https://discord.gg/kwVZfAKQHU">
            <button className="btn" style={{marginLeft:'auto', marginRight: 'auto', marginTop: '40px'}}>
              <span className="btn__content">Join</span>
              <span className="btn__label"></span>
            </button>
          </Link>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[25px] ml-0">
            <path d="M0.999999 0V24H25" stroke="#7D7C7C"/>
          </svg>
        </Stack>
      </Container>
    </div>
  );
}