import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            textTransform: 'none',
            fontSize: '32px',
            '@media (min-width:600px)': {
              fontSize: '60px',
            }
          },
        }
      ],
    },
  },
});

export default function NFTTabs(props) {
  return (
    <ThemeProvider theme={theme}>
    <Container maxWidth="lg">
      <Stack style={{ fontFamily: 'Tomorrow' }} spacing={2} direction="row" justifyContent={"center"}>
        <Stack direction="column" spacing={-2}>
          <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 002</Typography>
          <Button style={{color:'white', fontFamily: 'Orbitron' }} variant="dashed" onClick={props.handleSelectedClick}>
            {props.tab ==0 ?
              'Selected NFTs'
              :
              <div style={{ color:'black', textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff' }}>
                Selected NFTs
              </div>
            }
          </Button>
        </Stack>
        <div className="text-[#fa9e2b] pt-8 text-6xl">/</div>
        <Stack direction="column" spacing={-2}>
          <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 003</Typography>
          <Button style={{color: 'white', fontFamily: 'Orbitron' }} variant="dashed" onClick={props.handleAllClick}>
            {props.tab ==1 ?
              'All NFTs'
              :
              <div style={{ color:'black', textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff' }}>
                All NFTs
              </div>
            }
          </Button>
        </Stack>
      </Stack>
    </Container>
    </ThemeProvider>
  );
}