import './App.css';
import '@rainbow-me/rainbowkit/styles.css';

import ResponsiveAppBar from './components/AppBar';
import Footer from './components/Footer';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/HomePage';
import User from './pages/ProfilePage';
import Register from './pages/ConfigPage';
import Profile from './pages/SavedConfigPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Amplify } from 'aws-amplify';
import ContextProvider from './context/UserContext';
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  AvatarComponent
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
} from 'wagmi/chains';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  ledgerWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';
import maleNft from './assets/9.png';
import femaleNft from './assets/8.png';
import { searchUsers as searchUserQuery} from './graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

const muiDarkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// Configure wagmi library
const defaultChains = [mainnet, polygon, optimism, arbitrum, base, zora]
const { chains, publicClient } = configureChains(defaultChains, [
  publicProvider(),
]);

const projectId = '21e9c01ed3ec7bf7b5220c4fe21b7223';

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      metaMaskWallet({ projectId, chains }),
    ],
  },
  {
    groupName: 'Others',
    wallets: [
      coinbaseWallet({ chains, appName: 'MEW3 App' }),
      walletConnectWallet({ projectId, chains }),
      ledgerWallet({ projectId, chains }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

// Configure AWS Amplify
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_NEXT_PUBLIC_REGION,
    identityPoolId: process.env.REACT_APP_NEXT_PUBLIC_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_NEXT_PUBLIC_WEB_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
    mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_NEXT_PUBLIC_API_NAME,
        endpoint: process.env.REACT_APP_NEXT_PUBLIC_API_BASE_URL,
      },
    ],
  },
});

const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
  const [url, setUrl] = React.useState("/loading.png"); 
  API.graphql(graphqlOperation(searchUserQuery, {filter: { address: { eq: address }}, authMode: 'API_KEY'})).then((response) => 
    {if (response.data.searchUsers.items.length > 0) setUrl(response.data.searchUsers.items[0].profilePic)});
  return (<img
      src={url}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
    />
  )
};


function App() {
  return (
    <ThemeProvider theme={muiDarkTheme}>
      <Router>
        <div className="App">
          <header className="App-header bg-[url('./assets/bg.png')] bg-center bg-fixed bg-cover">
            <WagmiConfig config={config}>
              <RainbowKitProvider avatar={CustomAvatar} theme={darkTheme({
                accentColor: '#fa9e2b',
                accentColorForeground: 'black',
                borderRadius: 'medium'})} chains={chains}>
                <ContextProvider>
                  <ResponsiveAppBar />
                  <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/gallery' element={<User />} />
                    <Route path='/gallery/:username' element={<User />} />
                    <Route path='/register' element={<Register />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/profile/:username' element={<Profile />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                  <Footer />
                </ContextProvider>
              </RainbowKitProvider>
            </WagmiConfig>
          </header>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
