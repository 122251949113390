import { Alchemy, Network } from "alchemy-sdk";
const alchemyApiKey = "lXfGwC8un1Ggv6ew_R0KR-3cF9LwZy9B";
const chains = [Network.ETH_MAINNET, Network.MATIC_MAINNET];
const chain_briefs = new Map([[Network.ETH_MAINNET, "ethereum"], [Network.MATIC_MAINNET, "matic"]]);

async function getNfts(address, pageKeys) {

  if (!address) {
    return [[], null, false];
  }
  let res = [];
  let newPageKeys = new Map();
  let hasMoreNfts = false;
  await Promise.all(chains.map( async (chain) => {
    if (pageKeys && !pageKeys.get(chain)) {
      return;
    }
    const config = {
      apiKey: alchemyApiKey,
      network: chain,
    };
    
    const alchemy = new Alchemy(config);
    let nfts = await alchemy.nft.getNftsForOwner(address, {pageKey: pageKeys ? pageKeys.get(chain) : null, excludeFilters: "spam"});
    nfts.ownedNfts.map((nft) => {
      const opensea_url = "https://opensea.io/assets/" + chain_briefs.get(chain) + "/" + nft.contract.address + "/" + nft.tokenId;
      nft.opensea = opensea_url;
    });
    if (nfts.pageKey != null) {
      hasMoreNfts = true;
    }
    newPageKeys.set(chain, nfts.pageKey);
    res = res.concat(nfts.ownedNfts);
  }));
  let validNfts = [];
  for (let i = 0; i < res.length; i++) {
    const media = res[i].media;
    if (media.length < 1 || media[0].gateway == null || media[0].gateway == "") {
      continue;
    }
    validNfts.push(res[i]);
  }
  return [validNfts, newPageKeys, hasMoreNfts];
}

export default getNfts;