import { useCallback, useState, useContext, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DialogActions from '@mui/material/DialogActions';
import DataTable from './NFTTable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ButtonBaseDemo from './ImageButtons';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsmobile from '../aws-exports';
import {
  createUser as createUserMutation,
  createNft as createNftMutation,
} from '../graphql/mutations';
import { getUser as  getUserQuery, searchUsers as searchUserQuery} from '../graphql/queries';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAccount } from "wagmi";
import getNfts from "./utils/GetNfts";
import verifyOwner from './utils/VerifyOwner';
Amplify.configure(awsmobile);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: 400,
  border: '2px solid #fff',
  p: 4
};

const Page = () => {
  const [nameValue, setNameValue] = useState("");
  const [description, setDescription] = useState("");
  const maxCharacters = 200; // Change this to your desired maximum character count
  const [coverType, setCoverType] = useState("Solid");
  const [nfts, setNfts] = useState();
  const [selected, setSelected] = useState();
  const [registered, setRegisterd] = useState(false); 
  const [verifyOpen, setVerifyOpen] = useState(false);
  const successWords = "Congratulations! This username can be used.";
  const [verifyWords, setVerifyWords] = useState("Checking...");
  const [avatarID, setAvatarID] = useState('');
  const { address, isConnected } = useAccount();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const [stepsOpen, setStepsOpen] = useState(false);
  const [stepWords, setStepWords] = useState('Step 1: Validating Username');
  const [stepDisplayWords, setStepDisplayWords] = useState('Please do not refresh the page and wait while we are processing your request.');
  const [stepState, setStepState] = useState(0);
  const [owning, setOwning] = useState(false);

  async function getAllNfts() {
    let res = [];
    // const addres = "0xd0594d3984eb00D2730Db00531F87E89A63645Dd";
    // const addres = "0x3D7e39DFa6d78472A5d660a343d2060509d1Ef7d";
    const [validNfts, newPageKeys, hasMoreNfts] = await getNfts(address, null);
    res = res.concat(validNfts);
    let pk = newPageKeys, keepOn = hasMoreNfts;
    while (keepOn) {
      const [newNfts, nextPageKeys, hasMore] = await getNfts(address, pk);
      res = res.concat(newNfts);
      pk = nextPageKeys;
      keepOn = hasMore;
    }
    return res;
  }

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disableStepClose = () => {};

  const enableStepClose = () => {
    setStepsOpen(false);
  }

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  async function handlePublish() {
    setStepsOpen(true);
    API.graphql(graphqlOperation(searchUserQuery, {filter: { username: { eq: nameValue }}, authMode: 'API_KEY'})).then((response) => {
      if (response.data.searchUsers.items.length > 0) {
        setStepState(1);
        setStepDisplayWords('Validation Failed.')
      } else {
        //create user profile
        setStepWords('Step 2: Creating Profile')
        const data = {
          username: nameValue,
          address: address,
          description: description,
          profilePic: avatarID
        };
        API.graphql(graphqlOperation(createUserMutation, { input: data, authMode: 'API_KEY'})).then(
          async apiData => {
            const userID = apiData.data.createUser.username;
            //add NFTs
            for(var i in selected) {    
              var item = selected[i];
              const nftData = {
                title: item.title,
                collection: item.collection,
                token: item.tokenid,
                address: item.contract,
                url: item.preview,
                userID: userID,
                openseaurl: item.opensea
              };
              const result = await API.graphql(graphqlOperation(createNftMutation, { input: nftData, authMode: 'API_KEY'}));   
            };
            //verify user information
            const getUserResult = await API.graphql(graphqlOperation(getUserQuery, {username: userID, authMode: 'API_KEY'}));
            window.location.replace("/gallery/" + nameValue);
          });
      }
    });
  }

  function handleBackground(e) {
    setCoverType(e.target.outerText);
  }

  function updateSelectedNFT(props) {
    setSelected(props);
  }

  function updateSelectedAvatar(props) {
    setAvatarID(props);
  }

  if (!isConnected || !address) {
    return (
      <div style={{fontFamily: "Orbitron", fontSize: "24px", textAlign: "center"}}>Please log in</div>
    );
  }

  API.graphql(graphqlOperation(searchUserQuery, {filter: { address: { eq: address }}, authMode: 'API_KEY'})).then((response) => {
    if (response.data.searchUsers.items.length > 0) {
      setRegisterd(true)
    }
  });

  if (isConnected && address) {
    verifyOwner(address).then((owns) => {
      if (owns != owning) {
        setOwning(owns)
      }
    })
  }

  if (owning && !nfts) {
    getAllNfts().then((allNfts) => {
      setNfts(allNfts);
    });
  }

  function handleVerify() {
    API.graphql(graphqlOperation(searchUserQuery, {filter: { username: { eq: nameValue }}, authMode: 'API_KEY'})).then((response) => {
      if (response.data.searchUsers.items.length > 0) {
        setVerifyWords("Sorry, this username is already taken!");
        setVerifyOpen(true);
      }
      else {
        setVerifyWords(successWords);
        setVerifyOpen(true);
      }
    });
  }

  const handleVerifyClose = () => {
    setVerifyOpen(false);
  };

  const handleDescriptionChange = (event) => {
    const newValue = event.target.value;
    
    // Check if the newValue exceeds the maximum character limit
    if (newValue.length <= maxCharacters) {
      setDescription(newValue);
    }
  };

  if (registered) {
    return (<div style={{fontFamily: "Orbitron", fontSize: "24px", textAlign: "center"}}>You already registered!</div>);
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={3}
                  >
                    <Typography variant="h7">
                      Basic Details
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9}
                  >
                    <Stack spacing={3}>
                      {!owning ? 
                        <TextField
                          defaultValue={"***Please mint NFT first***"}
                          disabled
                          required
                          sx={{
                            flexGrow: 1,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderStyle: 'dashed'
                            }
                          }}
                        />
                      : null}
                      <Stack direction="row" spacing={3}>
                        <TextField
                          label="Username"
                          onChange={(newValue) => setNameValue(newValue.target.value)}
                          sx={{ flexGrow: 1 }}
                        />
                        {owning ? 
                          <button className="btn float-right" onClick={handleVerify}>
                            <span className="btn__content">Verify</span>
                            <span className="btn__label"></span>
                          </button>
                        : null}
                      </Stack>
                      <TextField
                        defaultValue={address}
                        disabled
                        label="Wallet Address"
                        required
                        sx={{
                          flexGrow: 1,
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderStyle: 'dashed'
                          }
                        }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={description} // Use 'value' instead of 'defaultValue' to control the input value
                        onChange={handleDescriptionChange}
                        label="Bio"
                        helperText={`${description.length}/${maxCharacters} characters`} // Display character count
                        inputProps={{ maxLength: maxCharacters }} // Set the maximum character limit
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Dialog
              open={verifyOpen}
              onClose={handleVerifyClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" sx={{fontFamily: 'Orbitron'}}>
                {"Verify username:"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{color: '#fa9e2b', fontSize: '18px', fontFamily: 'Tomorrow'}}>
                  {verifyWords}
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={3}
                  >
                    <Typography variant="h7">
                      Background Cover
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9}
                  >
                    <div>
                      <Typography sx={{ m: 3, fontFamily: 'Orbitron', fontSize: '18px', color: '#fa9e2b'}}>
                        {coverType} Background Cover
                      </Typography>
                      <ButtonBaseDemo handleBackground={handleBackground}/>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={3}
                  >
                    <Typography variant="h7">
                      Assets
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9}
                  >
                    <Typography sx={{ m: 3 , fontFamily: 'Orbitron'}}>
                      Choose <a className="colorlogo">maximum 9 items</a> to display in your gallery (you can always update them later).
                    </Typography>
                    <Stack direction="row">
                      <Typography sx={{ m: 4 , fontFamily: 'Orbitron'}}>
                        Selected Profile NFT:
                      </Typography>
                      <Avatar src={avatarID} sx={{ width: 68, height: 68 }} />
                    </Stack>
                    <DataTable nfts={nfts} updateNFT={updateSelectedNFT} updateAvatar={updateSelectedAvatar} alt="selected" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Grid
                  container
                  padding={3}
                >
                  <FormControlLabel
                    control={<Checkbox color="primary" name="read" value="yes"/>}
                    label="I have read and agree to the"
                  />
                  <Button onClick={handleClickOpen('paper')} sx={{marginLeft: '-10px', textTransform: 'none', fontSize: '16px'}}>terms and conditions</Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                  >
                    <DialogTitle id="scroll-dialog-title">Terms and Conditions for MEW3 Platform</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                      <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                      >
                        <p>1. Acceptance of Terms</p>
                        <p>By accessing or using the MEW3 platform (&quot;Platform&quot;), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use the MEW3 Platform.</p>
                        <p>2. Definitions</p>
                        <p>2.1. Web3: Refers to the decentralized web, including blockchain technologies, smart contracts, and decentralized applications (DApps).</p>
                        <p>3. Use of the Platform</p>
                        <p>3.1. You must be of legal age in your jurisdiction to use the MEW3 Platform.</p>
                        <p>3.2. You are responsible for maintaining the security of your MEW3 wallet and any associated private keys.</p>
                        <p>3.3. You agree not to engage in any unlawful, abusive, or harmful activities on the MEW3 Platform.</p>
                        <p>4. Privacy and Data</p>
                        <p>4.1. Our Privacy Policy outlines how we collect, use, and protect your personal information on the MEW3 Platform. By using the MEW3 Platform, you consent to the practices described in the Privacy Policy.</p>
                        <p>5. Intellectual Property</p>
                        <p>5.1. All content and materials on the MEW3 Platform, including text, images, logos, and software, are protected by intellectual property laws and belong to the MEW3 Platform owner or its licensors.</p>

                        <p>5.2. You may not reproduce, distribute, or use any content from the MEW3 Platform without prior written consent.</p>
                        <p>6. Disclaimers and Limitations</p>
                        <p>6.1. The MEW3 Platform is provided &quot;as is,&quot; and we make no warranties or guarantees of any kind, including fitness for a particular purpose or non-infringement.</p>
                        <p>6.2. We are not responsible for any loss, damage, or harm resulting from your use of the MEW3 Platform.</p>
                        <p>7. Third-Party Services</p>
                        <p>7.1. The MEW3 Platform may link to or integrate with third-party services or websites. We are not responsible for the content or actions of these third parties.</p>
                        <p>8. Termination</p>
                        <p>8.1. We reserve the right to terminate or suspend your access to the MEW3 Platform at our discretion, without notice.</p>
                        <p>9. Governing Law and Dispute Resolution</p>
                        <p>9.1. These Terms and Conditions are governed by the laws of [Jurisdiction]. Any disputes arising from or related to these terms shall be resolved through arbitration or in a court of competent jurisdiction.</p>
                        <p>10. Changes to Terms</p>
                        <p>10.1. We may update or modify these Terms and Conditions for the MEW3 Platform at any time. Your continued use of the MEW3 Platform after such changes constitutes acceptance of the updated terms.</p>
                        <p>11. Contact Information</p>
                        <p>11.1. If you have questions or concerns about these Terms and Conditions for the MEW3 Platform, please contact us at contact@mew3.net.</p>
                        <p>12. Entire Agreement</p>
                        <p>12.1. These Terms and Conditions for the MEW3 Platform constitute the entire agreement between you and the MEW3 Platform owner and supersede any prior agreements or understandings.</p>
                        <p>@2023</p>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Confirm</Button>
                    </DialogActions>
                  </Dialog>
                  {owning ? 
                    <button className="btn mx-auto mt-4" onClick={handlePublish}>
                      <span className="btn__content">Publish</span>
                      <span className="btn__label"></span>
                    </button>
                  : null}
                  <Modal
                    open={stepsOpen}
                    onClose={ stepState === 0 ? handleClose : enableStepClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography variant="h6" component="h2" sx={{fontFamily: 'Orbitron'}}>
                        {stepWords}
                      </Typography>
                      <Typography sx={{ mt: 2, fontFamily: 'Jura' }}>
                        <b>{stepDisplayWords}</b>
                      </Typography>
                      <Stack alignItems="center" marginTop="10px">
                        { stepState === 0 ? <CircularProgress color="warning" /> : <DangerousIcon color="error" sx={{ fontSize: 40 }}/> }
                      </Stack>
                    </Box>
                  </Modal>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Page;
