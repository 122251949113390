import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import logo from '../assets/hlogo-white.png';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import line from '../assets/svgs/footerLine.svg';
import useMobileSize from './utils/MobileSizeUtils';
import Link from '@mui/material/Link';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'left',
  fontSize: '18px',
  fontFamily: 'Jura',
  padding: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2)
}));

export default function Footer() {
  const isMobile = useMobileSize();
  return (
    <div>
      {isMobile? FooterOnMobile() : FooterOnDesktop()}
    </div>
  )
}

const FooterOnMobile = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ marginBottom: '50px', marginTop: '100px' }} >
          <Grid container spacing={0}>
            <Grid container xs={12}>
              <Grid xs={12}>
                <Item>
                  <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura'}}>The best web3 digital personal showcase platform which you can trust.</Typography>
                  <img className="h-7" src={line} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
                </Item>
              </Grid>
              <Grid container xs={12}>
                <Grid xs={6}>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>INFORMATION</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>About Us</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>Workflow</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>News</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>Home</Typography>
                  </Item>
                </Grid>

                <Grid xs={6}>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>CONTACT</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>MEW3, Seattle, WA, US</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>contact@mew3.net</Typography>
                  </Item>
                </Grid>

                <Grid xs={12}>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>TWITTER<ArrowOutwardIcon color="warning"/></Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>DISCORD<ArrowOutwardIcon color="warning"/></Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>TELEGRAM<ArrowOutwardIcon color="warning"/></Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>INSTAGRAM<ArrowOutwardIcon color="warning"/></Typography>
                  </Item>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Item>
                  <img className="h-7" src={logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
                </Item>
                <Item>
                  <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>@2023</Typography>
                </Item>
                <Item>
                  <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura', marginTop: '-10px'}}>MEW3 . All rights reserved .</Typography>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};


const FooterOnDesktop = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ marginBottom: '50px', marginTop: '100px' }} >
          <Grid container spacing={0}>
            <Grid xs={3}>
              <Item>
                <img className="h-7" src={logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
              </Item>
              <Item>
                <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>@2023</Typography>
              </Item>
            </Grid>
            <Grid container xs={9}>
              <Grid xs={12}>
                <Item>
                  <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Orbitron'}}>The best web3 digital personal showcase platform which you can trust.</Typography>
                  <img className="h-7" src={line} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}/>
                </Item>
              </Grid>
              <Grid container xs={12}>
                <Grid xs={4}>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>INFORMATION</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>About Us</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>Workflow</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>News</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>Home</Typography>
                  </Item>
                </Grid>

                <Grid xs={4}>
                  <Item>
                    <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>CONTACT</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>MEW3, Seattle, WA, US</Typography>
                  </Item>
                  <Item>
                    <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura'}}>contact@mew3.net</Typography>
                  </Item>
                </Grid>

                <Grid xs={4}>
                  <Item>
                    <Link href="https://twitter.com/MEW3official" target="_blank" underline="none">
                      <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>TWITTER<ArrowOutwardIcon color="warning"/></Typography>
                    </Link>
                  </Item>
                  <Item>
                    <Link target="_blank" href="https://discord.gg/kwVZfAKQHU" underline="none">
                      <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>DISCORD<ArrowOutwardIcon color="warning"/></Typography>
                    </Link>
                  </Item>
                  <Item>
                    <Link target="_blank" href="https://t.me/+CL_8-Rn9tK4yODI1" underline="none">
                      <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>TELEGRAM<ArrowOutwardIcon color="warning"/></Typography>
                    </Link>
                  </Item>
                  <Item>
                    <Link href="https://www.instagram.com/mew3_pioneer" target="_blank" underline="none">
                      <Typography align="left" component="legend" color="gray" sx={{fontSize: '14px', fontFamily: 'Jura'}}>INSTAGRAM<ArrowOutwardIcon color="warning"/></Typography>
                    </Link>                  
                  </Item>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Item>
                  <Typography align="left" component="legend" color="white" sx={{fontSize: '14px', fontFamily: 'Jura', marginTop: '30px'}}>MEW3 . All rights reserved .</Typography>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};