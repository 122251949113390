import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';


export default function DataTable({nfts, selectedNfts, updateNFT, updateAvatar}) {

  const columns = [
    { field: 'id', headerName: 'No.' , flex: 0.01},
    { field: 'title', headerName: 'Title', flex: 0.2},
    { field: 'collection', headerName: 'Collection', flex: 0.2},
    { field: 'contract', headerName: 'Contract', flex: 0.2},
    { field: 'tokenid', headerName: 'TokenID', flex: 0.1},
    {
      field: 'preview',
      headerName: 'Preview',
      sortable: false,
      flex: 0.2,
      renderCell: (params) => <Avatar sx={{ width: 60, height: 60, mx: 'auto' }} variant="rounded" src={params.value} />
    },
    {
      field: 'avatar',
      headerName: 'Avatar Pic',
      sortable: false,
      flex: 0.2,
      renderCell: (params) => {
        return <Button
          variant="outlined"
          onClick={(event) => {
            console.log(params.value);
            updateAvatar(params.value);
          }}
          color="warning"
          size="small"
        >Select</Button>
      }
    }
  ];

  // State to hold the selected rows
  const [selectedRows, setSelectedRows] = useState([]);

  let rows = [];

  for (let i = 0; i < (nfts ? nfts.length : 0); i++) {
    const nft = nfts[i];
    rows.push({
      id: i + 1,
      title: nft.title ? nft.title : "Unknown Title",
      collection: nft.contract.name ? nft.contract.name : "Unknown Collection",
      contract: nft.contract.address,
      tokenid: nft.tokenId,
      preview: nft.media[0].gateway,
      avatar: nft.media[0].gateway,
      opensea: nft.opensea
    });
  }

  // TODO: fix default selected and newly selected can not both work
  // useEffect(() => {
  //   // When the selectedNfts prop changes, update the selectedRows state
  //   const newSelectedRows = rows
  //     .filter((row) => selectedNfts.has(row.contract))
  //     .map((row) => row.id);
  //   setSelectedRows(newSelectedRows);
  // }, [selectedNfts, rows]);

  return (
    <div style={{ width: '100%', height: 400}}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50]}
        checkboxSelection
        // selectionModel={selectedRows}
        onSelectionModelChange={(ids) => {
          // Update the selectedRows state
          // TODO: fix default selected and newly selected can not both work
          // setSelectedRows(ids);
          const selectedIDs = new Set(ids);
          const selectedNewNFTs = rows.filter((row) => 
            selectedIDs.has(row.id),
          );
          updateNFT(selectedNewNFTs);
        }}
      />
    </div>
  );
}