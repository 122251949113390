import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  /* Default style for desktop */
  text-align: center;
  margin: 0;
  
  /* Style for mobile devices */
  @media (max-width: 768px) {
    text-align: center;
    margin: 0 auto; /* Center the button horizontally on mobile */
    display: block; /* Adjust display to ensure full-width alignment */
  }
`;

const AdjustableButton = ({ text, buttonClassName }) => {
  const renderButton = buttonClassName ? (
    <StyledButton className={buttonClassName}>
      <span className="btn__content">{text}</span>
      <span className="btn__label"></span>
    </StyledButton>
  ) : (
    <StyledButton>{text}</StyledButton>
  );

  return (
    <div>
      {renderButton}
    </div>
  );
};

export default AdjustableButton;
