import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Unstable_Grid2 as Grid
} from '@mui/material';

const theme = createTheme();

theme.typography.h3 = {
  fontSize: '1.8rem',
  '@media (min-width:600px)': {
    fontSize: '4.5rem',
  }
};

theme.typography.h4 = {
  fontSize: '0.8rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem',
  }
};

const HomePageDescription = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid
          container
          spacing={2}
        >
          <Grid xs={12}>
            <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 001</Typography>
            <Typography align="left" component="legend" color="white" variant="h3" sx={{fontFamily: 'Orbitron'}}>
              <div className="glitch-wrapper">
                 <div className="glitch" data-glitch="MEW3 : YOUR UNIQUE DIGITAL REALM">MEW3 : YOUR UNIQUE DIGITAL REALM</div>
              </div> 
            </Typography>
          </Grid>
          <Grid xs={6}></Grid>
          <Grid xs={6}>
            <Typography align="right" component="legend" className="text-gray-400" variant="h4" sx={{fontFamily: 'Orbitron', marginTop:'10px'}}>A simple and creative way to <a className="colorlogo">showcase</a> your <a className="colorlogo">NFT Collections</a> and <a className="colorlogo">share them</a> with friends.</Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default HomePageDescription;