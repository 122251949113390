import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import frame from '../assets/svgs/blackBg.svg'
import ImageGallery from './ImageGallery';
import upperLine from '../assets/svgs/upperLine.svg';
import bottomLine from '../assets/svgs/bottomLine.svg';

export default function Gallery({username, userNFTs}) {

  return (
    <div>
      <Container maxWidth="lg" sx={{marginTop: '40px'}}>
        <img src={upperLine} className="mx-auto"/>
      </Container>
      <Container maxWidth="xlg">
        <Box sx={{ backgroundImage: `url(${frame})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", 
        marginTop: "30px", marginBottom: "30px" }}>
          <div style = {{height:"80vh", width:"90vw", alignItems: "center"}}>
            <ImageGallery username={username} userNFTs={userNFTs}/>
          </div>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <img src={bottomLine} className="mx-auto"/>
      </Container>
    </div>
  );
}