import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Stack from '@mui/material/Stack';
import stepCard from '../assets/svgs/stepCard.svg';
import fontSizes from './utils/FontSizeUtils';
import useMobileSize from './utils/MobileSizeUtils';

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'left',
  fontSize: '18px',
  fontFamily: 'Jura',
  padding: theme.spacing(2),
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '20px',
  backgroundImage: `url(${stepCard})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  minHeight: "260px"
}));

export default function Steps() {
  const isMobile = useMobileSize();
  return (
    <div>
      {isMobile? StepsOnMobile() : StepsOnDesktop()}
    </div>
  )
}

const StepsOnMobile = () => {
  return (
    <div>
    <Container maxWidth="xs">
      <Stack direction="column" sx={{marginTop: '50px'}}>
        <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 003</Typography>
        <Typography align="left" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>HOW TO GENERATE</Typography>
        <Typography align="left" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>YOUR PAGE</Typography>
      </Stack>
    </Container>
    <Container maxWidth="xs">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-auto">
        <path d="M24 25V1H0" stroke="#7D7C7C"/>
      </svg>
      <Grid container md={12} sx={{marginTop: '20px'}} >
        <Item>
          <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura', marginTop: '12px'}}>STEP 01</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Mint MEW3 NFT</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Mint our NFTs on Opensea for early access to this innovative feature.</Typography>
        </Item>
        <Item>
          <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura', marginTop: '12px'}}>STEP 02</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Connect Wallet</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Connect your Web3 wallet to our platform and grant us sufficient access to read your assets.</Typography>
        </Item>
        <Item>
          <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura', marginTop: '12px'}}>STEP 03</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Customize & Publish</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Visit the "My Gallery" page to personalize your preferences, including profile details, gallery themes, and the selection of NFT assets for display.</Typography>
        </Item>
        <Item>
          <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura', marginTop: '12px'}}>STEP 04</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Share Your Page</Typography>
          <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '5px'}}>Share your web3 personal homepage with friends and join our thriving digital assets community.</Typography>
        </Item>
      </Grid>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-0">
        <path d="M0.999999 0V24H25" stroke="#7D7C7C"/>
      </svg>
    </Container>
    </div>
  );
}

const StepsOnDesktop = () => {
  return (
    <div>
    <Container maxWidth="lg">
      <Stack direction="column" sx={{marginTop: '50px'}}>
        <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 003</Typography>
        <Typography align="left" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>
          <div className="glitch-wrapper">
            <div className="glitch" data-glitch="HOW TO GENERATE YOUR PAGE">HOW TO GENERATE YOUR PAGE</div>
          </div>
        </Typography>
      </Stack>
    </Container>
    <Container maxWidth="md">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="relative bottom-[-10px] ml-auto">
        <path d="M24 25V1H0" stroke="#7D7C7C"/>
      </svg>
      <Grid container md={12} sx={{marginTop: '20px'}} >
        <Grid md={6} padding={2}>
          <Item>
            <div className="relative left-[25px] z-10">
              <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura'}}>STEP 01</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Mint MEW3 NFT</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Mint our NFTs on Opensea for early access to this innovative feature.</Typography>
            </div>
          </Item>
          <Item>
            <div className="relative left-[25px] z-10">
              <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura'}}>STEP 03</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Customize & Publish</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Visit the "My Gallery" page to personalize your preferences, including profile details, gallery themes, and the selection of NFT assets for display.</Typography>
            </div>
          </Item>
        </Grid>
        <Grid md={6} padding={2} sx={{marginTop: '83px'}}>
          <Item>
            <div className="relative left-[25px] z-10">
              <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura'}}>STEP 02</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Connect Wallet</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Connect your Web3 wallet to our platform and grant us sufficient access to read your assets.</Typography>
            </div>
          </Item>
          <Item>
            <div className="relative left-[25px] z-10">
              <Typography align="left" component="legend" color="#FA9E2C" sx={{fontSize: '16px', fontFamily: 'Jura'}}>STEP 04</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '24px', fontFamily: 'Jura', fontWeight: 'bold'}}>Share Your Page</Typography>
              <Typography align="left" component="legend" color="white" sx={{fontSize: '18px', fontFamily: 'Jura', maxWidth: '330px', marginTop: '15px'}}>Share your web3 personal homepage with friends and join our thriving digital assets community.</Typography>
            </div>
          </Item>
        </Grid>
      </Grid>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-0">
        <path d="M0.999999 0V24H25" stroke="#7D7C7C"/>
      </svg>
    </Container>
    </div>
  );
};