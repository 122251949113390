import '../App.css';
import NFTTabs from '../components/NFTTabs';
import Gallery from '../components/Gallery';
import AllGallery from '../components/AllGallery';
import Profile from '../components/Profile';
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getUser as  getUserQuery} from '../graphql/queries';
import { useAccount } from "wagmi";

function User() {
  const { username } = useParams();
  const [userAlias, setUserAlias] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [userDescription, setUserDescription] = useState(null);
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [userNFTs, setUserNFTs] = useState(null);
  const { address, isConnected } = useAccount();
  useEffect(() => {
    async function getProfileUser() {
      try {
        const getUserResult = await API.graphql(
          graphqlOperation(getUserQuery, { username, authMode: 'API_KEY' })
        );

        const userData = getUserResult.data.getUser;
        
        // Set userAlias and userDescription using useState
        setUserAlias(userData.username || ''); // Set to an empty string if it's null/undefined
        setUserDescription(userData.description || ''); // Set to an empty string if it's null/undefined
        setUserAddress(userData.address || '');
        if (userData.profilePic !== '') setUserProfilePic(userData.profilePic)
        else setUserProfilePic('https://img.freepik.com/free-vector/oops-404-error-with-broken-robot-concept-illustration_114360-1932.jpg?w=2000');
        setUserNFTs(userData.nfts);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    }

    getProfileUser(); // Call the function when the component mounts
  }, [username]);

  const [tab, setTab] = useState(0);  
  function handleSelectedClick() {
    setTab(0);
  }

  function handleAllClick() {
    setTab(1);
  }

  function isUserAuthenticated() {
    return address == userAddress
  }

  if (username && userNFTs)
  return (
    <div>
      <Container maxWidth="xlg">
        <Profile userAlias={userAlias} userAddress={userAddress} userDescription={userDescription} userProfilePic={userProfilePic} isAuthenticated={isUserAuthenticated()}/>
        <div className="mt-32">
          <NFTTabs tab={tab} handleSelectedClick={handleSelectedClick} handleAllClick={handleAllClick}/>
        </div>
        {tab < 1 ? <Gallery username={username} userNFTs={userNFTs}/> : <AllGallery userAddress={userAddress}/>}
      </Container>
    </div>
  );
}

export default User;