import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import seperator from '../assets/svgs/seperator.svg';
import discord from '../assets/svgs/discord.svg';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsmobile from '../aws-exports';
import { listUsers, listNfts } from '../graphql/queries';
import Page from './CreateProfile';
import SavedPage from './LoadProfile';
import fontSizes from './utils/FontSizeUtils';
import Link from '@mui/material/Link';
import { fa3, faM, faE, faW } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

Amplify.configure(awsmobile);

const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === '000',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  borderRadius: '4px',
  textAlign: 'left',
  fontSize: '18px',
  fontFamily: 'Jura',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

export default function SavedUserInfo({username}) {
  return (
    <div>
      <Container maxWidth="lg">
        <Grid container xs={12}>
          <Item className="mt-12">
            <Typography align="left" component="legend" color="#fa9e2b" sx={{fontSize: '16px', fontFamily: 'Jura'}}>&bull; 001</Typography>
            <Typography align="left" component="legend" color="white" sx={{fontSize: fontSizes, fontFamily: 'Orbitron'}}>Account Profile</Typography>
          </Item>
        </Grid>
        <Grid container md={12} sx={{marginTop: '20px'}}>
          <Grid xs={12}>
            <Item>
              <SavedPage username={username}/>
            </Item>
            <Item className="mt-8">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 40H80M40 0L40 80M46 40C46 43.3137 43.3137 46 40 46C36.6863 46 34 43.3137 34 40C34 36.6863 36.6863 34 40 34C43.3137 34 46 36.6863 46 40Z" stroke="#FA9E2C"/>
              </svg>
            </Item>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}