import * as THREE from 'three'
import { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useCursor, Image, Text } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import { easing } from 'maath'
import getUuid from 'uuid-by-string'
import nftFrame from '../assets/nftFrame.png';
import highlight from '../assets/svgs/highlight.svg';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const GOLDENRATIO = 1.6

export default function ImageGallery(props) {

  const nfts = props.userNFTs;
  let count = 0;
  const newImage = [
    // Front
    { position: [0, 0, 1.5], rotation: [0, 0, 0], url: '/placeholder1.png' },
    // Back
    { position: [-0.75, 0, -0.6], rotation: [0, 0, 0], url: '/placeholder2.png' },
    { position: [0.75, 0, -0.6], rotation: [0, 0, 0], url: '/placeholder3.png' },
    // Left
    { position: [-1.9, 0, -0.1], rotation: [0, Math.PI / 3.5, 0], url: '/placeholder4.png' },
    { position: [-2, 0, 1.5], rotation: [0, Math.PI / 3, 0], url: '/placeholder5.png' },
    { position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: '/placeholder6.png' },
    // Right
    { position: [1.9, 0, -0.1], rotation: [0, -Math.PI / 3.5, 0], url: '/placeholder7.png' },
    { position: [2, 0, 1.5], rotation: [0, -Math.PI / 3, 0], url: '/placeholder8.png' },
    { position: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: '/placeholder9.png' }
  ];
  nfts.items.forEach((nft) => {
    if (count < newImage.length) {
      newImage[count].url = "https://ik.imagekit.io/mew3/" + nft.url;
      newImage[count].nft = nft;
      count = count + 1;
    }
  });

  return (
    <Canvas dpr={[1, 1.5]} camera={{ fov: 40, position: [0, 2, 15] }}>
      <fog attach="fog" args={['#191920', 0, 15]} />
      <group position={[0, -0.7, 0.5]}>
        <Frames images={newImage} />
      </group>
    </Canvas>
  )
}

function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, GOLDENRATIO / 2 - 0.015, 2))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(0, 0, 5.5)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt)
    easing.dampQ(state.camera.quaternion, q, 0.4, dt)
  })
  return (
    <group
      ref={ref}
      onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

const truncateText = (text, length) => {
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length - 1) + "...";
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const image = useRef()
  const frame = useRef()
  const [, params] = useRoute('/item/:id')
  const [hovered, hover] = useState(false)
  const [linkHovered, linkHover] = useState(false);
  const [rnd] = useState(() => Math.random())
  const name = getUuid(url)
  const isActive = params?.id === name
  const nftCard = useLoader(THREE.TextureLoader, nftFrame)
  const nftHighlight = useLoader(THREE.TextureLoader, highlight)
  const directToOpensea = (openseaurl) => {
    if (isActive) {
      window.open(openseaurl, "_blank");
    }
  }
  useCursor(hovered)
  useFrame((state, dt) => {
    image.current.material.zoom = 1 + Math.sin(rnd * 10000 + state.clock.elapsedTime / 3) / 6
    easing.damp3(image.current.scale, [0.85 * 3.1 * (!isActive && hovered ? 0.93 : 1), 0.9 * 3.1 * (!isActive && hovered ? 0.905 : 1), 0], 0.1, dt)
    easing.dampC(frame.current.material.color, hovered ? 'orange' : 'white', 0.1, dt)
  })
  return (
    <group {...props}>
      <mesh
        name={name}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[1.2 / 4, GOLDENRATIO / 4, 1]}
        position={[0, GOLDENRATIO / 2, 0.3]}>
        <planeGeometry attach="geometry" args={[3, 3]} />
        <meshBasicMaterial attach="material" map={nftCard} toneMapped={false} fog={false} transparent={true} />
        <mesh ref={frame} raycast={() => null} position={[0, 0, 0.001]}>
          <planeGeometry attach="geometry" args={[3, 3]} />
          <meshBasicMaterial attach="material" transparent={true} map={nftHighlight} toneMapped={false} fog={false} />
        </mesh>
        <Text font="https://fonts.gstatic.com/s/orbitron/v9/yMJRMIlzdpvBhQQL_Qq7dys.woff" position={[-1.2, -0.9, 0.002]} fontSize={0.095} scale={[GOLDENRATIO + 0.3, 1.2, 1]} anchorX="left">
          {truncateText(props.nft ? props.nft.title : '', 23)}
        </Text>
        <Text font="https://fonts.gstatic.com/s/orbitron/v9/yMJRMIlzdpvBhQQL_Qq7dys.woff" position={[1.15, -1.115, 0.002]} fontSize={0.115} scale={[(GOLDENRATIO + 0.3) * 0.7, 1.2 * 0.7, 1]} anchorX="right">
          {truncateText(props.nft ? props.nft.collection : '', 22)}
        </Text>
        {props.nft ? <Text font="https://fonts.gstatic.com/s/orbitron/v9/yMJRMIlzdpvBhQQL_Qq7dys.woff" position={[1, -0.75, 0.002]} fontSize={0.115} scale={[(GOLDENRATIO + 0.3) * 0.5, 1.2 * 0.5, 1]} anchorX="right" visible={isActive ? true : false}
        color={linkHovered ? "yellow" : "#fa9e2b"} onPointerOver={(e) => (e.stopPropagation(), linkHover(true))} onPointerOut={() => linkHover(false)} onClick={() => directToOpensea(props.nft ? props.nft.openseaurl : '')}>
          -> More on OpenSea
        </Text> : null}
      </mesh>
      <mesh scale={[0.3, 0.3, 1]} position={[-0.015, 0.9, 0.299]}>
        <Image raycast={() => null} ref={image} url={url} toneMapped={false} />
      </mesh>
    </group>
  )
}