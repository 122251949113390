import { useCallback, useState, useContext, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import DialogActions from '@mui/material/DialogActions';
import DataTable from './NFTTable';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ButtonBaseDemo from './ImageButtons';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsmobile from '../aws-exports';
import {
  createUser as createUserMutation,
  createNft as createNftMutation,
  updateUser as updateUserMutation,
  deleteNft as deleteNftMutation

} from '../graphql/mutations';
import {
  getUser as  getUserQuery, 
  searchUsers as searchUserQuery,
  nftsByUserIDAndTitle as getNftsByUserQuery
} from '../graphql/queries';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAccount } from "wagmi";
import getNfts from "./utils/GetNfts";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

Amplify.configure(awsmobile);

const SavedPage = ({username}) => {
  const [nameValue, setNameValue] = useState("");
  const [description, setDescription] = useState("");
  const maxCharacters = 200; // Change this to your desired maximum character count
  const [coverType, setCoverType] = useState("Solid");
  const [nfts, setNfts] = useState();
  const [selectedNfts, setSelectedNfts] = useState();
  const [selectedNftAddresses, setSelectedNftAddresses] = useState();
  const [selected, setSelected] = useState();
  const [verifyOpen, setVerifyOpen] = useState(false);
  const successWords = "Congratulations! This username can be used.";
  const [verifyWords, setVerifyWords] = useState("Checking...");
  const [avatarID, setAvatarID] = useState('');
  const { address, isConnected } = useAccount();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  async function getAllNfts() {
    let res = [];
    // const address1 = "0xd0594d3984eb00D2730Db00531F87E89A63645Dd";
    // const address2 = "0x3D7e39DFa6d78472A5d660a343d2060509d1Ef7d";
    const [validNfts, newPageKeys, hasMoreNfts] = await getNfts(address, null);
    res = res.concat(validNfts);
    let pk = newPageKeys, keepOn = hasMoreNfts;
    while (keepOn) {
      const [newNfts, nextPageKeys, hasMore] = await getNfts(address, pk);
      res = res.concat(newNfts);
      pk = nextPageKeys;
      keepOn = hasMore;
    }
    return res;
  }

  async function fetchNFTsByUserIDAndTitle(userID) {
    try {
      const getNftsByUserResult = await API.graphql(
        graphqlOperation(getNftsByUserQuery, { userID, authMode: 'API_KEY' })
      );
      return getNftsByUserResult.data.nftsByUserIDAndTitle.items;
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      throw error;
    }
  }

  const [userAlias, setUserAlias] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userDescription, setUserDescription] = useState('');
  const [userProfilePic, setUserProfilePic] = useState('');
  const [loadingUserDescription, setLoadingUserDescription] = useState(true); // Initialize to true
  const [userNotFound, setUserNotFound] = useState(false); // Initialize to false

  useEffect(() => {
    async function getProfileUser() {
      try {
        //store the initial username for comparision
        setNameValue(username)

        const getUserResult = await API.graphql(
          graphqlOperation(getUserQuery, { username, authMode: 'API_KEY' })
        );

        const userData = getUserResult.data.getUser;
        
        // Set userAlias and userDescription using useState
        setUserAlias(userData.username || ''); // Set to an empty string if it's null/undefined
        setUserDescription(userData.description || ''); // Set to an empty string if it's null/undefined
        setUserAddress(userData.address || '');
        if (userData.profilePic !== '') setUserProfilePic(userData.profilePic);
        else setUserProfilePic('https://img.freepik.com/free-vector/oops-404-error-with-broken-robot-concept-illustration_114360-1932.jpg?w=2000');
        setAvatarID(userData.profilePic);

        setLoadingUserDescription(false); // Set loading state to false when done fetching
      } catch (error) {
        console.error('Error fetching user:', error);
        setUserNotFound(true); // Set User not found state to true
        setLoadingUserDescription(false); // Set loading state to false when done fetchqing
      }
    }

    getProfileUser(); // Call the function when the component mounts
  }, [username]);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  async function handlePublish() {
    handleVerifyUsername();
    //update user profile
    const data = {
      username: nameValue,
      address: address,
      description: userDescription,
      profilePic: avatarID
    };
    const apiData = await API.graphql(graphqlOperation(updateUserMutation, { input: data, authMode: 'API_KEY'}));
    const userID = apiData.data.updateUser.username;

    //delete previous nfts belonging to user
    for(var j in selectedNfts) {
      var item = selectedNfts[j];
      const nftData = {
        id: item.id
      };
      const result = await API.graphql(graphqlOperation(deleteNftMutation, { input: nftData, authMode: 'API_KEY'}));   
    }

    //add new nfts belonging to user
    for(var i in selected) {    
      var item = selected[i];
      const nftData = {
        title: item.title,
        collection: item.collection,
        token: item.tokenid,
        address: item.contract,
        url: item.preview,
        userID: userID,
        openseaurl: item.opensea
      };
      const result = await API.graphql(graphqlOperation(createNftMutation, { input: nftData, authMode: 'API_KEY'}));
    };

    //verify user information
    const getUserResult = await API.graphql(graphqlOperation(getUserQuery, {username: userID, authMode: 'API_KEY'}));
    window.location.replace("/gallery/" + nameValue);
  }

  function handleBackground(e) {
    setCoverType(e.target.outerText);
  }

  function updateSelectedNFT(props) {
    setSelected(props);
  }

  function updateSelectedAvatar(props) {
    setAvatarID(props);
  }

  if (!isConnected || !address) {
    return (
      <div style={{fontFamily: "Orbitron", fontSize: "24px", textAlign: "center"}}>Please log in</div>
    );
  }

  if (!nfts) {
    getAllNfts().then((allNfts) => {
      setNfts(allNfts);
    });
  }

  if (!selectedNfts) {
    fetchNFTsByUserIDAndTitle(username).then((userNfts) => {
      const nftAddressArray = userNfts.map((item) => (item.address))
      const nftAddressSet = new Set(nftAddressArray);

      setSelectedNftAddresses(nftAddressSet);
      setSelectedNfts(userNfts);
    });
  }

  const isUsernameValid = (username) => {
    if (nameValue == username) {
      return true; //Return as there is no change for user name
    }
    API.graphql(graphqlOperation(searchUserQuery, {filter: { username: { eq: nameValue }}, authMode: 'API_KEY'})).then((response) => {
      if (response.data.searchUsers.items.length > 0) {
        setVerifyOpen(true);
      }
      else {
        setVerifyOpen(true);
      }
    });
  }

  function handleVerifyUsername() {
    //TODO fix the alart window to align with design
    if (!isUsernameValid(nameValue)) {
      alert(`Username '${nameValue}' is not valid.`);
    }
  }

  const handleVerifyClose = () => {
    setVerifyOpen(false);
  };

  const handleDescriptionChange = (event) => {
    const newValue = event.target.value;
    
    // Check if the newValue exceeds the maximum character limit
    if (newValue.length <= maxCharacters) {
      setUserDescription(newValue);
    }
  };

  if (loadingUserDescription) {
    // Show a loading message or indicator while userDescription is being fetched
    return (
      <div>Loading user description...</div>
    );
  }

  if(userNotFound) {
    return (
      <div style={{ fontWeight: 'bold', color: 'orange', fontSize: '24px' }}>
        User Not Found
      </div>
    );
  } else {
    return (
      <>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 1
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      xs={12}
                      md={3}
                    >
                      <Typography variant="h7">
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={9}
                    >
                      <Stack spacing={3}>
                        <Stack direction="row" spacing={3}>
                          <TextField
                            label="Username"
                            // disable username editing untill DDB update
                            disabled
                            defaultValue={username}
                            onChange={(newValue) => setNameValue(newValue.target.value)}
                            sx={{ flexGrow: 1 }}
                          />
                          <button className="btn float-right" onClick={handleVerifyUsername}>
                            <span className="btn__content">Verify</span>
                            <span className="btn__label"></span>
                          </button>
                        </Stack>
                        <TextField
                          defaultValue={address}
                          disabled
                          label="Wallet Address"
                          required
                          sx={{
                            flexGrow: 1,
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderStyle: 'dashed'
                            }
                          }}
                        />
                        <TextField
                          label="Bio"
                          defaultValue={userDescription} // Use 'value' instead of 'defaultValue' to control the input value
                          fullWidth
                          multiline
                          rows={3}
                          onChange={handleDescriptionChange}
                          helperText={`${userDescription.length}/${maxCharacters} characters`} // Display character count
                          inputProps={{ maxLength: maxCharacters }} // Set the maximum character limit
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Dialog
                open={verifyOpen}
                onClose={handleVerifyClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" sx={{fontFamily: 'Orbitron'}}>
                  {"Verify username:"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{color: '#fa9e2b', fontSize: '18px', fontFamily: 'Tomorrow'}}>
                    {verifyWords}
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      xs={12}
                      md={3}
                    >
                      <Typography variant="h7">
                        Background Cover
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={9}
                    >
                      <div>
                        <Typography sx={{ m: 3, fontFamily: 'Orbitron', fontSize: '18px', color: '#fa9e2b'}}>
                          {coverType} Background Cover
                        </Typography>
                        <ButtonBaseDemo handleBackground={handleBackground}/>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      xs={12}
                      md={3}
                    >
                      <Typography variant="h7">
                        Assets
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={9}
                    >
                      <Typography sx={{ m: 3 , fontFamily: 'Orbitron'}}>
                        Choose <a className="colorlogo">maximum 9 items</a> to display in your gallery (you can always update them later).
                      </Typography>
                      <Stack direction="row">
                        <Typography sx={{ m: 4 , fontFamily: 'Orbitron'}}>
                          Selected Profile NFT:
                        </Typography>
                        <Avatar src={avatarID} sx={{ width: 68, height: 68 }} />
                      </Stack>
                      <DataTable nfts={nfts} selectedNfts={selectedNfts} updateNFT={updateSelectedNFT} updateAvatar={updateSelectedAvatar} alt="selected" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Grid container justify="center" alignItems="center" spacing={6}>
                    <Grid item>
                      <button className="btn" onClick={handlePublish}>
                        <span className="btn__content">Save</span>
                        <span className="btn__label"></span>
                      </button>
                    </Grid>
                    <Grid item>
                      <Link to={`/gallery/${username}`}>
                        <button className="btn">
                          <span className="btn__content">Cancel</span>
                          <span className="btn__label"></span>
                        </button>
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Stack>
          </Container>
        </Box>
      </>
    );
  }
};

export default SavedPage;
