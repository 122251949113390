import { useState, useEffect } from "react";
import styled from "styled-components";

const RenderedText = styled.p`
    ${(props) => props.styling}
`;

const GlitchingTypingText = (props) => {
    const { children, element, styling } = props;
    const text = children;
    const [sliceIndex, setSliceIndex] = useState(0);
    const [typingIntervalID, setTypingIntervalID] = useState(null);

    useEffect(() => {
        const tID = setInterval(() => {
            setSliceIndex((index) => index + 1);
        }, 100);
        setTypingIntervalID(tID);

        return () => clearInterval(tID);
    }, []);

    useEffect(() => {
        if (sliceIndex >= text.length) {
            clearInterval(typingIntervalID);
        }
    }, [sliceIndex]);

    return (
        <RenderedText as={element} styling={styling}>
            {text.slice(0, sliceIndex)}
        </RenderedText>
    );
};

export default GlitchingTypingText;