import '../App.css';
import SavedUserInfo from '../components/SavedRegister';
import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';

function Profile() {
  const { username } = useParams();
  const [tab, setTab] = useState(0);
  
  function handleSelectedClick() {
    setTab(0);
  }

  function handleAllClick() {
    setTab(1);
  }

  return (
    <div>
      <SavedUserInfo username={username}/>
    </div>
  );
}

export default Profile;